<script>
import { required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
export default {
  model: {
    prop: "priorityCalculations",
    event: "input",
  },
  props: ["priorityCalculations", "isMerged"],
  components: {
    draggable,
  },
  data() {
    return {
      calculationTypes: [
        //"CriticalRatio",
        "ProgressDependencies",
        "OperationDate",
        "DeliveryDate",
        "InstallationDate",
        "ProductionDate",
        "Attributes",
        "Keywords",
      ],
      mergeProgressCalculationTypes: [
        //"CriticalRatio",
        "OperationDate",
        "DeliveryDate",
        "InstallationDate",
        "ProductionDate",
        "Attributes",
        "Keywords",
      ],
      calculationType: null,
    };
  },
  computed: {
    calculationTypesNotSelected() {
      let types = undefined;
      if (this.isMerged) {
        types = this.mergeProgressCalculationTypes;
      } else {
        types = this.calculationTypes;
      }
      let unselected = types.filter((ct) => {
        //return true;
        let inList = this.priorityCalculations.$model.filter((pc) => {
          return pc.calculationType == ct;
        });

        return inList.length == 0;
      });

      return unselected;
    },
  },
  methods: {
    addPriorityCalculation() {
      this.$v.calculationType.$touch();

      if (!this.$v.$invalid) {
        let model = {
          calculationType: this.calculationType,
          criticalRatioCalculationConfig: null,
          progressDependenciesCalculationConfig: null,
          attributesCalculationConfig: this.calculationType === "Attributes" ? { attributesDependencies: []} : null,
          keywordsCalculationConfig: null,
          order: this.priorityCalculations.$model.length + 1
        };

        this.priorityCalculations.$model.push(model);
        this.$emit("added", model);

        this.calculationType = null;
        this.$v.$reset();
      }
    },
    deletePriorityCalculations(index) {
      this.priorityCalculations.$model.splice(index, 1);
      this.$emit("select", null);
    },
    selectPriorityCalculationConfig(priorityCalculation) {
      this.$emit("select", priorityCalculation);
    },
    changeOrder() {
      this.priorityCalculations.$model.map((d, index) => {
        d.order = index + 1;
      });
    },
  },
  validations: {
    calculationType: { required },
  },
};
</script>
<template>
  <div>
    <b-form-group>
      <label>{{ isMerged ? $t("general.selectCalculationTypesForScheduling") : $t("general.selectCalculationTypesForScheduling") }}</label>
      <small class="mb-1" style="display: block">{{
        $t("general.chooseCalculationsForPriorityTxt")
      }}</small>
      <div
        style="display: flex; align-items: center"
        :class="{
          'is-invalid': $v.calculationType.$error,
        }"
      >
        <multiselect
          :validator="$v.calculationType"
          :class="{
            'is-invalid': $v.calculationType.$error,
          }"
          class="mr-2"
          style="max-width: 300px"
          :placeholder="$t('general.chooseCalculationType')"
          v-model="calculationType"
          :options="calculationTypesNotSelected"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
        </multiselect>

        <b-button
          @click="addPriorityCalculation()"
          style="position: relative; top: -1px"
          class="ml-1"
          variant="success"
          >{{ $t("general.add") }}</b-button
        >
      </div>

      <div v-if="$v.calculationType.$error" class="invalid-feedback">
        <span v-if="!$v.calculationType.required">{{
          $t("general.requiredValue")
        }}</span>
      </div>
    </b-form-group>
    <draggable
      v-model="priorityCalculations.$model"
      tag="div"
      @change="changeOrder"
    >
      <div
        class="light-draggable-card"
        style="display: flex; align-items: center"
        v-for="(pc, index) in priorityCalculations.$model"
        :key="index"
      >
        <div style="text-align: center; width: 70px">
          {{ index + 1 }}
        </div>
        <div style="flex: 1">
          <b-badge style="font-size: 1.1em">{{
            $t("general." + pc.calculationType)
          }}</b-badge>
        </div>
        <div
          v-if="
            pc.calculationType == 'CriticalRatio' ||
            pc.calculationType == 'ProgressDependencies' ||
            pc.calculationType == 'Attributes' ||
            pc.calculationType === 'Keywords'
          "
          style="text-align: center; width: 50px"
        >
          <i
            @click="selectPriorityCalculationConfig(pc)"
            style="font-size: 1.3em; cursor: pointer"
            class="far fa-eye text-primary mr-1"
          ></i>
        </div>
        <div style="text-align: center; width: 50px">
          <i
            @click="deletePriorityCalculations(index)"
            style="font-size: 1.3em; cursor: pointer"
            class="fas fa-trash-alt text-danger"
          ></i>
        </div>
      </div>
    </draggable>
  </div>
</template>