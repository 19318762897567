<script>
import { required } from "vuelidate/lib/validators";
export default {
  model: {
    prop: "criticalRatioCalculationConfig",
    event: "input",
  },
  props: ["criticalRatioCalculationConfig"],
  components: {},
  data() {
    return {
      dateTypes: ["Delivery", "Installation", "Operation", "Production"],
    };
  },
  methods: {},
  computed: {
  },
  validations: {
    criticalRatioCalculationConfig: {
      nva: { required },
      dateType: { required },
    },
  },
};
</script>
<template>
  <div>
    <h5 class="mb-3">{{ $t("general.CriticalRatio") }}</h5>
    <b-form-group>
      <label>{{ $t("general.attribute") }}</label>
      <small class="mb-1" style="display: block">{{
        $t("general.chooseProgressAttributeTxt")
      }}</small>
      <multiselect
        style="max-width: 220px"
        :placeholder="$t('general.chooseAttribute')"
        v-model="criticalRatioCalculationConfig.attribute"
        :options="measureTypes"
        :allow-empty="false"
        :show-labels="false"
      >
        <template slot="singleLabel" slot-scope="props">
          <span>{{ $t("general." + props.option) }}</span>
        </template>
        <template slot="option" slot-scope="props">
          <span>{{ $t("general." + props.option) }}</span>
        </template>
      </multiselect>
    </b-form-group>
    <b-form-group>
      <label>{{ $t("general.nva") }}</label>
      <small class="mb-1" style="display: block">{{
        $t("general.nvaDescription")
      }}</small>
      <b-form-input
        type="number"
        step="0.01"
        v-model="criticalRatioCalculationConfig.nva"
        placeholder="0"
        style="max-width: 90px; height: 38px"
      >
      </b-form-input>
    </b-form-group>
    <b-form-group>
      <label>{{ $t("general.criticalRatioFormula") }}</label>
      <small class="mb-1" style="display: block">{{
        $t("general.chooseDateTypeForCriticalRatioFormulaTxt")
      }}</small>
      <div style="display: flex; align-items: center">
        <multiselect
          style="max-width: 170px"
          :placeholder="$t('general.selectCalculationTypes')"
          v-model="criticalRatioCalculationConfig.dateType"
          :options="dateTypes"
          :allow-empty="false"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
        </multiselect>
        <div class="ml-2">
          - {{ $t("general.now") }} /
          {{ $t("general.residualChargeAtProgress") }}
        </div>
      </div>
    </b-form-group>
  </div>
</template>