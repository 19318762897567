<script>
import draggable from "vuedraggable";
import { required } from "vuelidate/lib/validators";
export default {
  model: {
    prop: "keywordsCalculationConfig",
    event: "input",
  },
  props: ["keywordsCalculationConfig"],
  components: {
    draggable,
  },
  data() {
    return {
      keywordsForm: {
        keyword: null,
        reference: null,
      },
    };
  },
  methods: {
    deleteKeywordDependency(index) {
      this.keywordsCalculationConfig.keywordsDependencies.splice(index, 1);
      this.changeOrder();
    },
    addKeyword() {
      this.$v.keywordsForm.$touch();

      if (!this.$v.keywordsForm.$invalid) {
        let model = {
          keyword: this.keywordsForm.keyword,
          reference: this.keywordsForm.reference,
          order: this.keywordsCalculationConfig.keywordsDependencies.length + 1,
        };
        if (
          this.keywordsCalculationConfig.keywordsDependencies == null ||
          typeof this.keywordsCalculationConfig.keywordsDependencies ==
            "undefined"
        ) {
          this.keywordsCalculationConfig.keywordsDependencies = [];
        }
        this.keywordsCalculationConfig.keywordsDependencies.push(model);
        this.keywordsForm.reference = null;
        this.keywordsForm.keyword = null;
        this.$v.keywordsForm.$reset();
      }
    },
    changeOrder() {
      this.keywordsCalculationConfig.keywordsDependencies.map((d, index) => {
        d.order = index + 1;
      });
    },
  },
  computed: {
    references() {
      return ["ProjectName", "CustomerName", "ProjectType", "TemplateName"];
    },
  },
  validations: {
    keywordsForm: {
      keyword: { required },
      reference: { required },
    },
  },
};
</script>
<template>
  <div>
    <h5>{{ $t("general.Keywords") }}</h5>
    <p class="card-title-desc">
      {{ $t("general.chooseKeywordsForPriority") }}
    </p>
    <b-form
      @submit.prevent="addKeyword"
      style="display: flex; zalign-items: baseline"
    >
      <b-form-group class="mr-2" style="position: relative">
        <label>{{$t("general.reference")}}</label>
        <multiselect
          :validator="$v.keywordsForm.reference"
          :class="{
            'is-invalid': $v.keywordsForm.reference.$error,
          }"
          style="width: 220px"
          :placeholder="$t('general.chooseReference')"
          v-model="keywordsForm.reference"
          :options="references"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
        </multiselect>
        <div v-if="$v.keywordsForm.reference.$error" class="invalid-feedback">
          <span v-if="!$v.keywordsForm.reference.required">{{
            $t("general.requiredValue")
          }}</span>
        </div>
      </b-form-group>
      <b-form-group class="mr-2" style="position: relative">
        <label>{{ $t("general.keyword") }}</label>
        <b-input
          :validator="$v.keywordsForm.keyword"
          :class="{
            'is-invalid': $v.keywordsForm.keyword.$error,
          }"
          style="width: 220px"
          :placeholder="$t('general.chooseKeyword')"
          v-model="keywordsForm.keyword"
          :show-labels="false"
        ></b-input>
        <div v-if="$v.keywordsForm.keyword.$error" class="invalid-feedback">
          <span v-if="!$v.keywordsForm.keyword.required">{{
            $t("general.requiredValue")
          }}</span>
        </div>
      </b-form-group>

      <b-form-group
        style="position: relative; display: flex; flex-direction: column"
      >
        <b-button
          style="margin-top: 27.5px; display: block"
          type="submit"
          variant="success"
          >{{ $t("general.add") }}</b-button
        >
      </b-form-group>
    </b-form>

    <div class="row mt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mb-0 table-hover">
            <thead class="thead-light">
              <tr>
                <th style="text-align: center">#</th>
                <th style="text-align: center">
                  {{ $t("general.reference") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.keyword") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.delete") }}
                </th>
              </tr>
            </thead>
            <draggable
              v-model="keywordsCalculationConfig.keywordsDependencies"
              tag="tbody"
              @change="changeOrder"
            >
              <tr
                v-for="(
                  ad, index
                ) in keywordsCalculationConfig.keywordsDependencies"
                :key="index"
                style="cursor: move"
              >
                <td style="text-align: center">{{ index + 1 }}</td>
                <td style="text-align: center">
                  {{ $t("general." + ad.reference) }}
                </td>
                <td style="text-align: center">
                  {{ ad.keyword }}
                </td>
                <td style="text-align: center">
                  <i
                    @click="deleteKeywordDependency(index)"
                    style="font-size: 1.3em; cursor: pointer"
                    class="fas fa-trash-alt text-danger mr-1"
                  ></i>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>