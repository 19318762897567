<script>
export default {
  props: ["title", "message"],
  data() {
    return {
      isLoading: false,
      showModal: false,
      deleteFonction: null,
      closeFonction: null,
      entity: null,
      entityName: null,
    };
  },
  methods: {
    show(entity, entityName) {
      this.entity = entity;
      this.entityName = entityName;
      this.showModal = true;
      return new Promise((resolve, reject) => {
        this.deleteFonction = (entity) => {
          this.isLoading = true;
          resolve(entity);
        };
        this.closeFonction = () => {
          reject();
        };
      }).finally(() => {
        this.isLoading = false;
        this.showModal = false;
        this.entity = null;
      });
    },
    close() {},
  },
};
</script>
<template>
  <b-modal v-model="showModal" :title="title" style="position: relative" no-close-on-backdrop>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    ></loading>
    <p>{{ message }}</p>
    <p class="text-center font-italic" v-if="entityName">{{ entityName }}</p>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="danger"
          class="float-right"
          @click="deleteFonction(entity)"
        >
          {{ $t("general.delete") }}
        </b-button>
        <b-button class="mr-2 float-right" @click="closeFonction">
          {{ $t("general.close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>