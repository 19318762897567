<script>
import AttributeCalculationConfig from "./attribute-calculation-config";
import CriticalRatioCalculationConfig from "./critical-ratio-calculation-config";
import ProgressDependenciesCalculationConfig from "./dependency-calculation-config";
import KeywordsCalculationConfig from "./keywords-calculation-config";
export default {
  model: {
    prop: "priorityCalculation",
    event: "input",
  },
  props: ["priorityCalculation"],
  components: {
    AttributeCalculationConfig,
    CriticalRatioCalculationConfig,
    ProgressDependenciesCalculationConfig,
    KeywordsCalculationConfig,
  },
};
</script>
<template>
  <div>
    <!-- Attributes form -->
    <div class="row" v-if="priorityCalculation.calculationType == 'Attributes'">
      <div class="col-12">
        <AttributeCalculationConfig v-model="priorityCalculation.attributesCalculationConfig" />
      </div>
    </div>

    <!-- Critical ratio form -->
    <div
      class="row"
      v-if="priorityCalculation.calculationType == 'CriticalRatio'"
    >
      <div class="col-12">
        <CriticalRatioCalculationConfig v-model="priorityCalculation.criticalRatioCalculationConfig" />
      </div>
    </div>
    <div class="row" v-if="priorityCalculation.calculationType == 'ProgressDependencies'">
      <div class="col-12">
        <ProgressDependenciesCalculationConfig v-model="priorityCalculation.progressDependenciesCalculationConfig" />
      </div>
    </div>
    <div class="row" v-if="priorityCalculation.calculationType == 'Keywords'">
      <div class="col-12">
        <KeywordsCalculationConfig v-model="priorityCalculation.keywordsCalculationConfig" />
      </div>
    </div>
  </div>
</template>