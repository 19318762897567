<script>
import draggable from "vuedraggable";
import { required } from "vuelidate/lib/validators";
export default {
  model: {
    prop: "dependencyCalculationConfig",
    event: "input",
  },
  props: ["dependencyCalculationConfig"],
  components: {
    draggable,
  },
  data() {
    return {
      dependencyForm: {
        progress: null,
        minPercentCompleted: 0,
        minRespectedOrderBy: "NextProgress",
      },
      progressDependenciesOrderByOption: ["OrderByPercentDesc", "NextProgress"],
    };
  },
  methods: {
    deleteProgressDependency(index) {
      this.dependencyCalculationConfig.progressDependencies.splice(index, 1);
      this.changeOrder();
    },
    addDependency() {
      this.$v.dependencyForm.$touch();

      if (!this.$v.dependencyForm.$invalid) {
        let model = {
          progress: this.dependencyForm.progress,
          minPercentCompleted: this.dependencyForm.minPercentCompleted / 100,
          minRespectedOrderBy: this.dependencyForm.minRespectedOrderBy,
        };
        if (
          this.dependencyCalculationConfig.progressDependencies == "undefined"
        ) {
          this.dependencyCalculationConfig.progressDependencies = [];
        }

        this.dependencyCalculationConfig.progressDependencies.push(model);
        this.dependencyForm.progress = null;
        this.dependencyForm.minPercentCompleted = 0;
        this.$v.dependencyForm.$reset();
      }
    },
    changeOrder() {
      this.dependencyCalculationConfig.progressDependencies.map((d, index) => {
        d.order = index + 1;
      });
    },
  },
  computed: {
    progress() {
      return this.$store.state.calendar.progress;
    },
  },
  validations: {
    dependencyForm: {
      progress: { required },
      minPercentCompleted: { required },
    },
  },
};
</script>
<template>
  <b-form @submit.prevent="addDependency">
    <div class="row">
      <div class="col-12">
        <h5>{{ $t("general.ProgressDependencies") }}</h5>
        <span class="card-title-desc">
          <p>{{ $t("general.progressDependenciesTxt") }}</p>
          <p>{{ $t("general.progressDependenciesTxt2") }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="display: flex; align-items: baseline">
        <b-form-group>
          <label>{{ $t("general.progress") }}</label>
          <div
            style="display: flex; align-items: center"
            :class="{
              'is-invalid': $v.dependencyForm.progress.$error,
            }"
          >
            <multiselect
              class="mr-2"
              :class="{
                'is-invalid': $v.dependencyForm.progress.$error,
              }"
              :validator="$v.dependencyForm.progress"
              style="width: 100%; width: 250px; max-width: 300px"
              :placeholder="$t('general.chooseProgress')"
              v-model="dependencyForm.progress"
              :options="progress"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
          </div>
          <div
            v-if="$v.dependencyForm.progress.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.dependencyForm.progress.required">{{
              $t("general.requiredValue")
            }}</span>
          </div>
        </b-form-group>
        <b-form-group>
          <label>{{ $t("general.minWorkOrder%Completion") }}</label>
          <div
            style="display: flex; align-items: center"
            :class="{
              'is-invalid': $v.dependencyForm.minPercentCompleted.$error,
            }"
          >
            <b-form-input
              class="mr-2"
              type="number"
              v-model="dependencyForm.minPercentCompleted"
              :class="{
                'is-invalid': $v.dependencyForm.minPercentCompleted.$error,
              }"
              placeholder="0"
              style="max-width: 90px; height: 38px"
            >
            </b-form-input>
          </div>
          <div
            v-if="$v.dependencyForm.minPercentCompleted.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.dependencyForm.minPercentCompleted.required">{{
              $t("general.requiredValue")
            }}</span>
          </div>
        </b-form-group>
      </div>

      <div class="col-12">
        <label>{{ $t("general.orderByIfMinRespected") }}</label>
        <div style="display: flex; align-items: center">
          <multiselect
            class="mr-2"
            style="width: 100%; width: 250px; max-width: 300px"
            :placeholder="$t('general.chooseOrderByOption')"
            v-model="dependencyForm.minRespectedOrderBy"
            :options="progressDependenciesOrderByOption"
            :show-labels="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span>{{ $t("general." + props.option) }}</span>
            </template>
            <template slot="option" slot-scope="props">
              <span>{{ $t("general." + props.option) }}</span>
            </template>
          </multiselect>
          <b-button
            style="position: relative; top: -1px"
            type="submit"
            variant="success"
            >{{ $t("general.add") }}</b-button
          >
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mb-0 table-hover">
            <thead class="thead-light">
              <tr>
                <th style="text-align: center">#</th>
                <th style="text-align: center">
                  {{ $t("general.progress") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.minPercentCompleted") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.minRespectedOrderBy") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.delete") }}
                </th>
              </tr>
            </thead>
            <draggable
              v-model="dependencyCalculationConfig.progressDependencies"
              tag="tbody"
              @change="changeOrder"
            >
              <tr
                v-for="(
                  pd, index
                ) in dependencyCalculationConfig.progressDependencies"
                :key="index"
                style="cursor: move"
              >
                <td style="text-align: center">{{ index + 1 }}</td>
                <td style="text-align: center">
                  <b-badge
                    style="font-size: 1.1em"
                    :style="{ 'background-color': pd.progress.colorHexa }"
                    >{{ pd.progress.name }}</b-badge
                  >
                </td>
                <td style="text-align: center">
                  {{ Math.round(pd.minPercentCompleted * 100) }}%
                </td>
                <td style="text-align: center">
                  {{ $t("general." + pd.minRespectedOrderBy) }}
                </td>
                <td style="text-align: center">
                  <i
                    @click="deleteProgressDependency(index)"
                    style="font-size: 1.3em; cursor: pointer"
                    class="fas fa-trash-alt text-danger mr-1"
                  ></i>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </b-form>
</template>