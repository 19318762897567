<script>
import draggable from "vuedraggable";
import { required } from "vuelidate/lib/validators";
export default {
  model: {
    prop: "attributesCalculationConfig",
    event: "input",
  },
  props: ["attributesCalculationConfig"],
  components: {
    draggable,
  },
  data() {
    return {
      attributeForm: {
        attribute: null,
        orderBy: "Descending",
      },
      orderByTypes: ["Ascending", "Descending"],
    };
  },
  methods: {
    deleteAttributeDependency(index) {
      this.attributesCalculationConfig.attributesDependencies.splice(index, 1);
      this.changeOrder();
    },
    addAttribute() {
      this.$v.attributeForm.$touch();

      if (!this.$v.attributeForm.$invalid) {
        let model = {
          attribute: this.attributeForm.attribute,
          orderBy: this.attributeForm.orderBy,
          order:
            this.attributesCalculationConfig.attributesDependencies.length + 1,
        };
        if (
          this.attributesCalculationConfig.attributesDependencies == null ||
          typeof this.attributesCalculationConfig.attributesDependencies ==
            "undefined"
        ) {
          this.attributesCalculationConfig.attributesDependencies = [];
        }
        this.attributesCalculationConfig.attributesDependencies.push(model);
        this.attributeForm.attribute = null;
        this.$v.attributeForm.$reset();
      }
    },
    changeOrder() {
      this.attributesCalculationConfig.attributesDependencies.map(
        (d, index) => {
          d.order = index + 1;
        }
      );
    },
  },
  computed: {
  },
  validations: {
    attributeForm: {
      attribute: { required },
      orderBy: { required },
    },
  },
};
</script>
<template>
  <div>
    <h5>{{ $t("general.attributes") }}</h5>
    <p class="card-title-desc">
      {{ $t("general.chooseAttributesForPriority") }}
    </p>
    <b-form
      @submit.prevent="addAttribute"
      style="display: flex; zalign-items: baseline"
    >
      <b-form-group class="mr-2" style="position: relative">
        <label>Attribut</label>
        <multiselect
          :validator="$v.attributeForm.attribute"
          :class="{
            'is-invalid': $v.attributeForm.attribute.$error,
          }"
          style="width: 220px"
          :placeholder="$t('general.chooseAttribute')"
          v-model="attributeForm.attribute"
          :options="measureTypes"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
        </multiselect>
        <div v-if="$v.attributeForm.attribute.$error" class="invalid-feedback">
          <span v-if="!$v.attributeForm.attribute.required">{{
            $t("general.requiredValue")
          }}</span>
        </div>
      </b-form-group>
      <b-form-group class="mr-2" style="position: relative">
        <label>{{ $t("general.orderBy") }}</label>
        <multiselect
          style="width: 160px"
          :placeholder="$t('general.chooseOrderBy')"
          v-model="attributeForm.orderBy"
          :options="orderByTypes"
          :allow-empty="false"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ $t("general." + props.option) }}</span>
          </template>
        </multiselect>
      </b-form-group>

      <b-form-group
        style="position: relative; display: flex; flex-direction: column"
      >
        <b-button
          style="margin-top: 27.5px; display: block"
          type="submit"
          variant="success"
          >{{ $t("general.add") }}</b-button
        >
      </b-form-group>
    </b-form>

    <div class="row mt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mb-0 table-hover">
            <thead class="thead-light">
              <tr>
                <th style="text-align: center">#</th>
                <th style="text-align: center">
                  {{ $t("general.attribute") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.orderBy") }}
                </th>
                <th style="text-align: center">
                  {{ $t("general.delete") }}
                </th>
              </tr>
            </thead>
            <draggable
              v-model="attributesCalculationConfig.attributesDependencies"
              tag="tbody"
              @change="changeOrder"
            >
              <tr
                v-for="(
                  ad, index
                ) in attributesCalculationConfig.attributesDependencies"
                :key="index"
                style="cursor: move"
              >
                <td style="text-align: center">{{ index + 1 }}</td>
                <td style="text-align: center">
                  {{ $t("general." + ad.attribute) }}
                </td>
                <td style="text-align: center">
                  {{ $t("general." + ad.orderBy) }}
                </td>
                <td style="text-align: center">
                  <i
                    @click="deleteAttributeDependency(index)"
                    style="font-size: 1.3em; cursor: pointer"
                    class="fas fa-trash-alt text-danger mr-1"
                  ></i>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>