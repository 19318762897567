var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.isMerged ? _vm.$t("general.selectCalculationTypesForScheduling") : _vm.$t("general.selectCalculationTypesForScheduling")))]),_c('small',{staticClass:"mb-1",staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.$t("general.chooseCalculationsForPriorityTxt")))]),_c('div',{class:{
        'is-invalid': _vm.$v.calculationType.$error,
      },staticStyle:{"display":"flex","align-items":"center"}},[_c('multiselect',{staticClass:"mr-2",class:{
          'is-invalid': _vm.$v.calculationType.$error,
        },staticStyle:{"max-width":"300px"},attrs:{"validator":_vm.$v.calculationType,"placeholder":_vm.$t('general.chooseCalculationType'),"options":_vm.calculationTypesNotSelected,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}},{key:"option",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}}]),model:{value:(_vm.calculationType),callback:function ($$v) {_vm.calculationType=$$v},expression:"calculationType"}}),_c('b-button',{staticClass:"ml-1",staticStyle:{"position":"relative","top":"-1px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.addPriorityCalculation()}}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1),(_vm.$v.calculationType.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.calculationType.required)?_c('span',[_vm._v(_vm._s(_vm.$t("general.requiredValue")))]):_vm._e()]):_vm._e()]),_c('draggable',{attrs:{"tag":"div"},on:{"change":_vm.changeOrder},model:{value:(_vm.priorityCalculations.$model),callback:function ($$v) {_vm.$set(_vm.priorityCalculations, "$model", $$v)},expression:"priorityCalculations.$model"}},_vm._l((_vm.priorityCalculations.$model),function(pc,index){return _c('div',{key:index,staticClass:"light-draggable-card",staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"text-align":"center","width":"70px"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticStyle:{"flex":"1"}},[_c('b-badge',{staticStyle:{"font-size":"1.1em"}},[_vm._v(_vm._s(_vm.$t("general." + pc.calculationType)))])],1),(
          pc.calculationType == 'CriticalRatio' ||
          pc.calculationType == 'ProgressDependencies' ||
          pc.calculationType == 'Attributes' ||
          pc.calculationType === 'Keywords'
        )?_c('div',{staticStyle:{"text-align":"center","width":"50px"}},[_c('i',{staticClass:"far fa-eye text-primary mr-1",staticStyle:{"font-size":"1.3em","cursor":"pointer"},on:{"click":function($event){return _vm.selectPriorityCalculationConfig(pc)}}})]):_vm._e(),_c('div',{staticStyle:{"text-align":"center","width":"50px"}},[_c('i',{staticClass:"fas fa-trash-alt text-danger",staticStyle:{"font-size":"1.3em","cursor":"pointer"},on:{"click":function($event){return _vm.deletePriorityCalculations(index)}}})])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }