export default {
    getSortFct() {
        return (a, b) => {
            return a.order > b.order
                ? 1
                : a.order < b.order
                    ? -1
                    : 0;
        };
    }
}